<template>
  <div>
    <!-- <v-btn @click="createUploadWidgetVue(cloudinary)">
      Upload files
    </v-btn> -->
  </div>
</template>

<script>
export default {
  // mounted() {
  //   this.createCloudinaryScript();
  // },
  // methods: {
  //   createCloudinaryScript() {
  //     let externalScript = document.createElement("script");
  //     externalScript.setAttribute(
  //       "src",
  //       "https://upload-widget.cloudinary.com/global/all.js"
  //     );
  //     document.head.appendChild(externalScript);
  //   },
  // createUploadWidgetVue(c) {
  //   c.createUploadWidget(
  //     {
  //       cloudName: "my_cloud_name",
  //       uploadPreset: "my_preset"
  //     },
  //     (error, result) => {
  //       if (!error && result && result.event === "success") {
  //       }
  //     }
  //   );
  // }
  // }
};
</script>
